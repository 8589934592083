exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-bedankt-jsx": () => import("./../../../src/pages/bedankt.jsx" /* webpackChunkName: "component---src-pages-bedankt-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-over-geen-vrijwilligersprobleem-jsx": () => import("./../../../src/pages/over-geen-vrijwilligersprobleem.jsx" /* webpackChunkName: "component---src-pages-over-geen-vrijwilligersprobleem-jsx" */),
  "component---src-pages-pakketten-jsx": () => import("./../../../src/pages/pakketten.jsx" /* webpackChunkName: "component---src-pages-pakketten-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */)
}

